import React from 'react';

import PageStatic from '../../components/page-static';
import ContactFormScreenBase from "../../components/contact/contact-form-screen-base";

function BookCPDPage(props) {
  return (
    <PageStatic seoTitle={'Sertus Gift Subscription'} {...props} noCtaBlock>
      <ContactFormScreenBase dataId={'giftSubscription'} />
    </PageStatic>
  );
}

export default BookCPDPage;
